<template>
  <div class='flex flex-col text-sm lg:text-base cursor-pointer text-gray-700 hover:bg-gray-100'
    :class='highlightClass'>
    <div class='truncate'>{{ notice.title }} </div>
    <div v-if='eventConfigShowNoticeDate'
      class='opacity-50 font-normal text-xs'>{{ formattedDate }}</div>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'NoticesRow',
  props: [
    'notice',
  ],
  computed: {
    ...mapState('notices', [
      'selectedNotice',
    ]),
    ...mapGetters('events', [
      'eventConfigShowNoticeDate',
    ]),
    isSelectedNotice () {
      return this.selectedNotice.id === this.notice.id
    },
    highlightClass () {
      if (this.isSelectedNotice) {
        return 'italic font-semibold text-gray-900 bg-white' 
      } else if (this.notice.isHighlighted) {
        return 'bg-blue-50' 
      } else {
        return ''
      }
    },
    formattedDate () {
      return dayjs(this.notice.createdAt).format('YYYY.MM.DD hh:mm:ss A')
    }
  },
  methods: {
    ...mapActions('notices', [
      'editNotice',
    ]),
  }
}
</script>
