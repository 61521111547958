<template>
    <div
      v-if='noticeViewMode'
      class='p-10 relative break-all'>
      <div v-if='hasSelectedNotice'>
        <h1 class='text-xl font-semibold mb-4'>{{selectedNotice.title}}</h1>
        <div ref='contentDisplay'></div>
        <button
          v-if='isAdmin'
          @click='startEditingNotice'
          class='absolute top-0 right-0 uppercase text-sm border border-transparent hover:border-gray-200 hover:shadow-md text-gray-700 hover:text-gray-900 py-2 px-3 rounded'>
          Edit
        </button>
      </div>
      <div v-else class='flex flex-row justify-center items-center opacity-30'>
        select notice to see detail
      </div>
    </div>
    <notice-form v-else />
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NoticeForm  from '@/components/notices/NoticeForm.vue'

export default {
  name: 'NoticesSelectedContent',
  components: {
    NoticeForm,
  },
  watch: {
    'selectedNotice.id': {
      handler: function (newVal) {
        this.$nextTick(() => {
          if (newVal && this.$refs.contentDisplay) {
            this.$refs.contentDisplay.innerHTML = this.selectedNotice.contents
          }
        })
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('notices', [
      'selectedNotice',
      'noticeViewMode'
    ]),
    ...mapGetters('notices', [
      'hasSelectedNotice'
    ]),
    ...mapGetters('users', [
      'isAdmin'
    ]),
  },
  methods: {
    ...mapActions('notices', [
      'getNotices',
      'startEditingNotice',
    ]),
  },
}
</script>
