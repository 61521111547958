<template>
  <div class='py-4 notice-edit'>
    <div class='flex flex-col px-4 mb-8'>
      <label class='uppercase text-sm text-gray-600'>title</label> 
      <input class='border px-2 py-2 rounded text-base' type='text' v-model='title'>
    </div>
    <div class='flex flex-col px-4 mb-8'>
    <label class='uppercase text-sm text-gray-600'>Content</label> 
      <vue-editor 
        v-model='contents'
        style='min-height: 40rem;'
        ></vue-editor>
    </div>
    <div class='px-4'>
      <button v-if='!hasSelectedNotice'
        class='border rounded-md px-8 py-4 uppercase bg-gray-900 text-white hover:shadow text-sm' 
        @click='submitCreateNotice'>
        create
      </button>
      <div v-else
        class='row-centered gap-x-4'>
        <button
          class='border rounded-md px-8 py-4 uppercase bg-gray-900 text-white hover:shadow text-sm' 
          @click='submitEditNotice'>
          Save Edits
        </button>
        <button
          class='border rounded-md px-2 py-4 uppercase text-red-700 border-transparent hover:border-red-400 hover:shadow text-sm' 
          @click='deleteNoticeConfirm'>
          Delete Notice
        </button>
      </div>

    </div>

  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields }              from 'vuex-map-fields'

export default {
  name: 'NoticeForm',
  components: {
    VueEditor,
  },
  computed: {
    ...mapState('notices', [
      'selectedNotice',
    ]),
    ...mapGetters('notices', [
      'hasSelectedNotice'
    ]),
    ...mapFields('notices', [
      'selectedNotice.title',
      'selectedNotice.contents'
    ]),
  },
  methods: {
    ...mapActions('notices', [
      'createNotice',
      'editNotice',
      'deleteNotice',
    ]),
    submitCreateNotice () {
      this.createNotice().then(() => {
        this.$alert('공지사항 등록 완료', '성공', {
          confirmButtonText: '완료',
          type: 'success'
        })
      }).catch(error => {
        this.$alert(error, 'error', {
          confirmButtonText: '확인',
          type: 'error'
        })
      })
    },
    submitEditNotice () {
      this.editNotice().then(() => {
        this.$alert('공지사항 수정 완료', '성공', {
          confirmButtonText: '완료',
          type: 'success'
        })
      }).catch(error => {
        this.$alert(error, 'error', {
          confirmButtonText: '확인',
          type: 'error'
        })
      })
    },
    deleteNoticeConfirm () {
      this.$confirm('Are you sure you want to delete this notice?', 'Warning', {
          confirmButtonText: 'Delete Notice',
          cancelButtonText: 'No, Keep Notice',
          type: 'warning'
        }).then(() => {
          this.deleteNotice(this.selectedNotice.id).then(resp => {
            this.$message.warning(`'${resp.title}' deleted.`)
          })
        }).catch(() => {
          console.log('dont delete')
          // do nothing. Pressed the cancel button to keep other device/browser logged in
        })
    },
  },
}
</script>

<style lang='scss'>
  .notice-edit .ql-editor {
    min-height:40rem;
  }
</style>
