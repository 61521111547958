<template>
  <div>
    <welcome-conference-banner v-if='showConferenceBanner' class='mb-8'/>
    <div class='flex flex-col lg:flex-row justify-start border lg:items-stretch items-start lg:mt-12'>
      <nav class='w-full lg:w-80 bg-gray-50 border-r border-b lg:border-b-0 flex-shrink-0 overflow-auto notice-nav'>
        <button
          v-if='isAdmin'
          @click='createNewNotice'
          class='py-1 lg:py-3 px-4 border-b w-full uppercase hover:shadow-md hover:border-2 text-gray-600 hover:text-gray-900 hover:bg-blue-100 flex flex-row items-center justify-center gap-x-2'>
          <plus-circle-icon />
          Add New Notice
        </button>
        <notices-row v-for='(notice, index) in notices' 
          :key='`notice-${index}`'
          :notice='notice'
          class='py-1 lg:py-3 px-4 border-b w-full'
          @click.native='updateNoticeQueryParam(notice.id)' />
      </nav>
      <div class='lg:overflow-auto w-full notice-content'>
        <notices-selected-content />
      </div>
    </div>
    <welcome-sponsor-carousel v-if='showSponsorCarousel' class='mt-8' />
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'
import { PlusCircleIcon }                   from '@vue-hero-icons/outline'
import NoticesSelectedContent               from '@/components/notices/NoticesSelectedContent.vue'
import NoticesRow                           from '@/components/notices/NoticesRow.vue'


export default {
  name: 'Notices',
  components: {
    NoticesSelectedContent,
    NoticesRow,
    PlusCircleIcon,
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    noticeIdInQueryParams: {
      handler: function (newVal) {
        if (newVal && this.notices.length > 0) {
          this.selectNotice(newVal)
        }
      },
    },
    noticesLength: {
      handler: function (newVal) {
        if (this.noticeIdInQueryParams && this.noticeIdInQueryParams !== this.selectedNotice.id) {
          if (newVal) {
            this.selectNotice(this.noticeIdInQueryParams)
          } else {
            this.defaultSelectNotice()
          }
        }
      },
    }
  },
  computed: {
    ...mapState('notices', [
      'notices',
      'selectedNotice',
    ]),
    ...mapGetters('users', [
      'isAdmin'
    ]),
    noticeIdInQueryParams () {
      return this.$route.query.notice_id ? parseInt(this.$route.query.notice_id) : null
    },
    noticesLength () {
      return this.notices.length
    },
  },
  methods: {
    ...mapActions('notices', [
      'getNotices',
      'selectNotice',
      'createNewNotice',
      'defaultSelectNotice',
    ]),
    updateNoticeQueryParam(noticeId) {
      this.$router.push({ query: { notice_id: noticeId } })
    },
  },
  mounted () {
    this.getNotices().then(() => {
      if (this.noticeIdInQueryParams) {
        this.selectNotice(this.noticeIdInQueryParams)
      } else {
        this.defaultSelectNotice()
      }
    })
  }
}
</script>

<style type='scss' scoped>
.notice-nav {
  min-height: calc(100vh - 44rem);
}

.notice-content {
  min-height: 24rem;
}

@media (min-width: 1024px) { 
  .notice-nav {
    min-height: calc(100vh - 22rem);
  }
  .notice-content {
    height: auto;
  }
}
</style>
